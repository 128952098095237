import React from 'react';
import './ConstructionPlaceholder.scss';

const ConstructionBanner = () => (
  <h2>This page is under construction, please check back in a while.</h2>
);
const CopyrightBanner = () => <h3>{`© ${new Date().getFullYear()}`}</h3>;

const ConstructionPlaceholder = () => (
  <div className='bannerContainer'>
    <ConstructionBanner />
    <CopyrightBanner />
  </div>
);

export default ConstructionPlaceholder;
