import React from 'react';
import './App.scss';
import ConstructionPlaceholder from './components/ConstructionPlaceholder';
import Navbar from './components/Navbar';

const App = () => {
  return (
    <div className={'App'}>
      <Navbar />
      <ConstructionPlaceholder />
    </div>
  );
};

export default App;
