import React, { useState } from 'react';
import './Switch.scss';
import { SwitchComponent } from './Switch.types';

const Switch: React.FC<SwitchComponent> = ({ switchCallback }) => {
  const [toggled, setToggled] = useState<boolean>(false);

  const onClickHandler = () => {
    setToggled(!toggled);
  };

  return (
    <div
      className='switch-container'
      onClick={() => {
        switchCallback();
        onClickHandler();
      }}
    >
      <div className={`switch-button ${toggled && 'toggled'}`}></div>
    </div>
  );
};
export default Switch;
