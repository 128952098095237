import React, { useEffect, useState } from 'react';
import './Navbar.scss';
import Switch from '../Switch';
import logo from '../../assets/logo.svg';

const Navbar = () => {
  const prefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark)')
    .matches;

  const [themeToggle, setThemeToggle] = useState<boolean>(false);

  useEffect(() => {
    if (prefersDarkTheme)
      themeToggle
        ? document.body.classList.add('light-theme')
        : document.body.classList.remove('light-theme');
    else
      themeToggle
        ? document.body.classList.add('dark-theme')
        : document.body.classList.remove('dark-theme');
  }, [prefersDarkTheme, themeToggle]);

  return (
    <div className='nav'>
      <img className='logo' src={logo} alt='logo' />
      <Switch switchCallback={() => setThemeToggle(!themeToggle)} />
    </div>
  );
};

export default Navbar;
